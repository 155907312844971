body {
  background-color: #eef0f4;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a{
  text-decoration: none;
}
.css-ubb9a5-MuiGrid2-root{
  gap: 0 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
}

*::-webkit-scrollbar-track {
  background-color: #F0F4F8; 
}

*::-webkit-scrollbar-thumb {
  background-color: #C1D3E0; 
  border-radius: 8px; 
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #A0B8CF; 
}
.css-9h2nlf-MuiChip-root{
  background-color: none !important;
  color: #6B7280 !important;
}
.MuiChip-label MuiChip-labelMedium css-1dybbl5-MuiChip-label{
  color:#6B7280  !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1kbclx2-MuiInputBase-root-MuiOutlinedInput-root{
  height: auto !important;
  background-color: #F9FAFB !important;
  color: #6B7280 !important;
}
/* .css-rtbmac-MuiTableCell-root{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #6B7280 !important;
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}