.progress {
    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &_circle {
      position: relative;
      width: 100%;
      height: 100%;
  
      &_bottom {
        fill: none;
        stroke: #C1E0FF; 
        stroke-width: 8; 
      }
  
      &_top {
        fill: none;
        stroke: #1D4F9E;
        stroke-width: 10;
        stroke-linecap: butt; 
        transform: rotate(-90deg); 
        transform-origin: 50% 50%;
      }
    }
  
    &_content {
      position: absolute;
      text-align: center;
      display: flex;
      flex-direction: column;
  
      &_percentage {
        font-size: 24px;
        font-weight: 800;
        color: #1D4F9E;
      }
  
      &_label {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
      }
    }
  }
  

  @media screen and (max-width: 385px) {
    .progress {
      width: 120px;
      height: 120px;
    }
  }