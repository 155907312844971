.MuiAccordion-root {
  border-radius: 8px !important;
  border: 1px solid #d5e1f9 !important;
  background: #f7faff !important;
  box-shadow: 1px 1px 5px 0px rgba(1, 87, 184, 0.2) !important;
}

.MuiAccordion-root:nth-child(even) {
  margin: 20px 0px !important;
}

.MuiAccordion-root::before {
  background: none !important;
}

.MuiAccordionSummary-root {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.accordianbox {
  border-radius: 8px;
  border: 1px solid #d5e1f9;
  background: #fff;
  padding: 20px;
}
